import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import {LayoutService} from "../layout.service";
import { HttpService } from '../../../shared/utils';
import { environment } from '../../../../environments/environment'
import { NgxSpinnerService } from 'ngx-spinner';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from 'moment';

@Component({
  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  
  constructor(private layoutService: LayoutService,private httpService: HttpService,private spinnerService: NgxSpinnerService) {
  }

  collapseMenu(){
    jQuery('aside').css('pointer-events','none');
    if(this.layoutService.store.mobileViewActivated){
      this.layoutService.onCollapseMenu();
    }
    else{
      this.layoutService.onCollapseMenu(true);
    }
    
    setTimeout(()=>{
      this.enableMouseEvent();
    }, 1000);
  }

  enableMouseEvent(){
    jQuery('aside').css('pointer-events','auto');
  }

  ngOnInit() {
  }

  downloadEntireDatabase(){
    this.spinnerService.show();

        var body = { "query": `SELECT \`${environment.BIGQUERY_DATASET}.fao_amis.amis_database\`.database_label,\`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\`.region_name,\`${environment.BIGQUERY_DATASET}.fao_amis.amis_product\`.product_name, \`${environment.BIGQUERY_DATASET}.fao_amis.amis_element\`.element_name, season, value, units FROM \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\` INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_element\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.element_code = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_element\`.element_code INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_product\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.product_code = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_product\`.product_code INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.region_code = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\`.region_code INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_database\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.database_type = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_database\`.database_code WHERE max_lastupdate = 1 AND \`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\`.amis_country=true
        ORDER BY database_label,region_name,product_name,element_name,season ASC LIMIT 50000 OFFSET 0` }

        var body2={ "query": `SELECT \`${environment.BIGQUERY_DATASET}.fao_amis.amis_database\`.database_label,\`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\`.region_name,\`${environment.BIGQUERY_DATASET}.fao_amis.amis_product\`.product_name, \`${environment.BIGQUERY_DATASET}.fao_amis.amis_element\`.element_name, season, value, units FROM \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\` INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_element\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.element_code = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_element\`.element_code INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_product\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.product_code = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_product\`.product_code INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.region_code = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\`.region_code INNER JOIN \`${environment.BIGQUERY_DATASET}.fao_amis.amis_database\` ON \`${environment.BIGQUERY_DATASET}.fao_amis.amis_data\`.database_type = \`${environment.BIGQUERY_DATASET}.fao_amis.amis_database\`.database_code WHERE max_lastupdate = 1 AND \`${environment.BIGQUERY_DATASET}.fao_amis.amis_country\`.amis_country=true
        ORDER BY database_label,region_name,product_name,element_name,season ASC LIMIT 50000 OFFSET 50000` }

        this.httpService.getApiPost(environment.BIGQUERY_ENDPOINT, body).subscribe(r => {

          this.httpService.getApiPost(environment.BIGQUERY_ENDPOINT, body2).subscribe(p => {

            this.spinnerService.hide();    
            let csvHeader=[
              {
                database_label:"Source",
                region_name:"Country/Region",
                product_name: "Commodity",
                element_name: "Element",
                season: "Season",
                value:"Value",
                units: "Element Unit"     
            }]
            new Angular5Csv(csvHeader.concat(r).concat(p), `AMIS-${moment(new Date()).format("YYYYMMDDHH:mm:ss")}`);

          })         
        })
  }

}
